<template>
    <div class="container">
        <Transition>
            <div class="notification success" v-if="showClipboard" @click="showClipboard = false;">
                <img src="../assets/copy-icon.svg" alt="Copy URL to clipboard" class="icon">
                Link copied to clipboard
            </div>
        </Transition>
        <h1 class="title">Conversion calculator</h1>
        <div class="input-wrap input-wrap-from">
            <input v-model="convertFromValue" class="input" :class="{'error': errorFromBorder}" @keydown="sanitize" @input="debouncedDoConversion()" />
            <v-select
                class="z-index-fix"
                v-model="convertFrom"
                :options="coins"
                label="displayedLabel"
                :clearable="false"
                @option:selected="doConversion()"
                :selectable="(option) => !option.is_title"
                :filter="filterItems"
            >
                <template #selected-option="option">
                    {{ option.symbol }}
                </template>
                <template v-slot:option="option">
                    {{ `${option.name} ${formatSymbol(option)}` }}
                </template>
            </v-select>
            <img src="../assets/switch-values.svg" @click="swapValues" alt="Swap values" class="swap-values">
        </div>
        <div class="input-wrap">
            <input v-model="convertToValue" class="input" :class="{'error': errorToBorder}" @keydown="sanitize" @input="debouncedDoConversion(true)">
            <v-select
                v-model="convertTo"
                :options="coins"
                label="displayedLabel"
                :clearable="false"
                @option:selected="doConversion()"
                :selectable="(option) => !option.is_title"
                :filter="filterItems"
            >
                <template #selected-option="option">
                    {{ option.symbol }}
                </template>
                <template v-slot:option="option">
                    {{ `${option.name} ${formatSymbol(option)}` }}
                </template>
            </v-select>
        </div>
        <div class="footer" v-if="conversionRates.from">
            <div class="conversion-rate">
                <img src="../assets/info-icon.svg" alt="Conversion rate" class="icon">
                <span class="text">{{`1 ${conversionRates.from} ≈ ${conversionRates.rate_str} ${conversionRates.to}`}}</span>
            </div>
            <div class="button" @click="generateLink">
                <img src="../assets/copy-icon.svg" alt="Copy URL to clipboard" class="icon">
                Copy link
            </div>
        </div>
    </div>
    <div class="error-wrap" v-if="error">
        {{ error }}
    </div>
    <div class="warning-wrap" v-if="warning">{{warning}}</div>
</template>

<script>
import TokenList from "@/components/TokenList";
import axios from 'axios'
import vSelect from 'vue-select'
import debounce from 'lodash.debounce';
import 'vue-select/dist/vue-select.css';
export default {
    name: "Convertor",
    components: {TokenList, vSelect},
    data() {
        return {
            convertFrom: {},
            convertFromValue: 1,
            convertTo: {},
            convertToValue: 0,
            coins: [],
            conversionRates: {
            },
            errorFromBorder: false,
            errorToBorder: false,
            error: null,
            queryParameters: null,
            warning: null,
            showClipboard: false,
        }
    },
    clipboardNotification: null,
    getters: {
        formattedFromValue: {
            get() {
                return `${convertFrom.name} (${convertFrom.symbol})`
            },
            set(newValue) {
                this.convertFrom = newValue;
            }
        }
    },
    methods: {
        generateLink() {
            let url = location.origin + location.pathname;

            // // Cut last letter
            // url = url.substring(0, url.length-1);

            // Add parameters
            url = url + encodeURI('?from=' + this.convertFrom.symbol + '&to=' + this.convertTo.symbol + '&from-value=' + this.convertFromValue);

            // Copy to clipboard
            navigator.clipboard.writeText(url);

            // Show notification
            this.showClipboard = true;
            this.$options.clipboardNotification = setTimeout(() => {
                this.showClipboard = false;
            }, 2000)
        },
        filterItems(options, searchTerm) {
            searchTerm = searchTerm.toLowerCase()
            const coins = options.filter(option => option.displayedLabel.toLowerCase().indexOf(searchTerm) > -1)

            const fiatIndex = coins.findIndex(item => !item.is_crypto)
            const cryptoIndex = coins.findIndex(item => item.is_crypto)

            if (fiatIndex > -1) {
                coins.splice(fiatIndex, 0, {symbol: '', name: 'Fiat', is_title: true})
            }

            if (cryptoIndex > -1) {
                coins.splice(cryptoIndex, 0, {symbol: '', name: 'Cryptocurrencies', is_title: true})
            }
            return coins;
        },
        formatSymbol(option) {
            return option.is_title ? '' : `(${option.symbol})`
        },
        sanitize(event) {
            const keyCode = event.which ? event.which : event.keyCode
            const allowedKeys = [8, 190, 110] // 188 = ,
            // 8 = backspace
            if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96) && (keyCode <= 105) || allowedKeys.includes(keyCode)) {
                return true
            } else {
                event.preventDefault()
            }
        },
        swapValues() {
            let aux = this.convertFrom;
            this.convertFrom = this.convertTo;
            this.convertTo = aux;
            this.doConversion();
        },
        loadCoins() {
            axios
                .post(
                    `https://pay4it.io:8391/availableCoins`,
                    {},
                    {
                        headers: {
                        },
                    }
                )
                .then(response => {
                    let coins = response.data.coins;
                    const fiatIndex = coins.findIndex(item => !item.is_crypto)

                    if (fiatIndex > -1) {
                        coins.splice(fiatIndex, 0, {symbol: '', name: 'Fiat', is_title: true})
                    }
                    const cryptoIndex = coins.findIndex(item => item.is_crypto)

                    if (cryptoIndex > -1) {
                        coins.splice(cryptoIndex, 0, {symbol: '', name: 'Cryptocurrencies', is_title: true})
                    }

                    this.coins = coins.map(coin => {
                    	let label = coin.name
                    	if (coin.symbol)
                    		label = `${coin.name} (${coin.symbol.toUpperCase()})`
                    	return {
                    		...coin,
                    		displayedLabel: label
                    	}
                    })

                    // Next we are going to check and see if there are any values sent as query params
                    const url = new URL(window.location.href)

                    let fromSymbol = url.searchParams.get('from')
                    if (this.returnIndex(fromSymbol) === -1)
                        fromSymbol = response.data.default_from

                    let toSymbol = url.searchParams.get('to')
                    if (this.returnIndex(toSymbol) === -1)
                        toSymbol = response.data.default_to

                    // Populate the values sent via query param or fallback on defaults sent in the API call
                    this.convertFrom = this.coins[this.returnIndex(fromSymbol)]
                    this.convertTo = this.coins[this.returnIndex(toSymbol)]

                    //Fallback on same pair
                    if(this.convertFrom.symbol === this.convertTo.symbol) {
                        this.convertTo = this.coins.filter(coin => (coin.symbol !== this.convertFrom.symbol && !coin.is_title))[0];
                    }

                    // Fallback to some default values (first in list, after titles) in case the value received via API
                    // does not exist in the coins list
                    // ex: we get 'USD' as fromSymbol but USD does not exist as a coin in the coins list (response.data.coins)
                    if(!this.convertFrom) {
                        this.convertFrom = this.coins.filter(coin => (coin.symbol !== this.convertTo.symbol && !coin.is_title && coin.is_crypto))[0];
                    }
                    if(!this.convertTo) {
                        this.convertTo = this.coins.filter(coin => (coin.symbol !== this.convertFrom.symbol && !coin.is_title && !coin.is_crypto))[0];
                    }

                    // Check for parameter fromValue
                    const fromValue = Number(url.searchParams.get('from-value'))
                    if (url.searchParams.get('from-value') !== null && !isNaN(Number(url.searchParams.get('from-value'))))
                    	this.convertFromValue = fromValue

                    this.doConversion(false);
                })
                .catch(error => {
                    console.log(error);
                })
        },
        fallBackToFirstOptionOfEachCategoryOfCoins(fromSymbol, toSymbol) {
            if(typeof(this.convertFrom) === 'undefined') {
                if(this.returnIndex(fromSymbol)) {

                }
            }
        },
        prepareSwapValues(reverse = false) {
            const data = {}

            if (reverse) {
                data.to = this.convertFrom.symbol
                data.from = this.convertTo.symbol
                data.from_value = this.convertToValue
            } else {
                data.from = this.convertFrom.symbol
                data.to = this.convertTo.symbol
                data.from_value = this.convertFromValue
            }

            return data
        },
        ongoingRequest: null,
        doConversion(reverse = false){
            const values = this.prepareSwapValues(reverse)

            // Check if there is any ongoingReq, and if, abort it before making another one
            if(this.$options.ongoingRequest) {
                console.log('ongoing req', this.$options.ongoingRequest);
                this.$options.ongoingRequest.abort()
            }

            // Save the abort controller for the next request
            this.$options.ongoingRequest = new AbortController();
            const signal = this.$options.ongoingRequest.signal;
            axios
                .post(
                    `https://pay4it.io:8391/convert`,
                    values,
                    {
                        signal: signal,
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    }
                )
                .then(response => {
                    if(response.data.result) {
                    	this.error = null
                    	if (response.data.message) {
                    		// warning
                    		this.warning = response.data.message
                    	} else {
                    		this.warning = ''
                    	}

                        if(reverse) {
                            this.convertToValue = response.data.from_value_str;
                            this.convertFromValue = response.data.to_value_str;
                        } else {
                            this.conversionRates = response.data;
                            this.convertFromValue = response.data.from_value_str;
                            this.convertToValue = response.data.to_value_str;
                        }
                    } else {
                        this.handleError(response.data.error);
                        this.warning = ''
                    }
                })
                .catch(error => {
                	this.warning = ''
                    this.handleError(error);
                })
        },
        handleError(error) {
            this.errorFromBorder = error.includes('from_value');
            this.errorToBorder = error.includes('to_value');
            this.error = error;
        },
        handleQueryParameters() {
            let uri = window.location.href.split('?');
            if (uri.length == 2) {
                let vars = uri[1].split('&');
                let getVars = {};
                let tmp = '';
                vars.forEach(function (v) {
                    tmp = v.split('=');
                    if (tmp.length == 2)
                        getVars[tmp[0]] = tmp[1];
                });
                this.queryParameters = getVars;
            }
        },
        returnIndex(symbol) {
            return this.coins.findIndex(item => item.symbol === symbol);
        }
    },
    created() {
        this.handleQueryParameters();
    },
    mounted() {
        this.debouncedDoConversion = debounce(this.doConversion, 1000)
        this.loadCoins();
    }
}
</script>
<style scoped lang="scss">
@import 'src/css/variables';
.v-enter-active,
.v-leave-active {
    transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
.container {
    padding: 20px;
    box-sizing: border-box;
    max-width: 404px;
    width: 100%;
    background: $white;
    border: 1px solid $border-grey;
    border-radius: 18px;
    position: relative;
    .notification {
        cursor: pointer;
        padding: 8px;
        background-color: $notification;
        position: absolute;
        color: $white;
        font-family: $main-font;
        font-size: 12px;
        border-radius: 6px;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
            width: 12px;
            margin-right: 6px;
        }
        &.success {
            background: $notification-success;
        }
    }
    .input-wrap {
        position: relative;
    }
    .input-wrap + .input-wrap {
        margin-top: 8px;
    }
    .input {
        min-height: 68px;
        box-sizing: border-box;
        width: 100%;
        border: 1px solid $border-grey;
        padding: 16px;
        border-radius: 12px;
        background: $white;
        color: $black;
        font-family: $main-font;
        font-size: 27px;
        font-weight: 400;
        &.error {
            border: 2px solid $red;
            background: $accent-red;
        }
    }
    .title {
        font-family: $main-font;
        font-weight: 800;
        font-size: 16px;
        color: $black;
        margin-top: 0;
        margin-bottom: 22px;
    }
    .swap-values {
        position: absolute;
        z-index: 1;
        cursor: pointer;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 50%);
    }
    .conversion-rate {
        display: flex;
        .icon {
            width: 13px;
            height: 13px;
            margin-right: 8px;
        }
        .text {
            font-family: "Inter", sans-serif;
            font-size: 11px;
            color: $black;
        }
    }
    &.wide {
    }
}
.v-select {
    position: absolute;
    z-index: 2;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    min-width: 100px;
}

.z-index-fix {
    z-index: 3;
}

.error-wrap, .warning-wrap {
    padding: 12px;
    margin-top: 8px;
    border-radius: 8px;
    color: $white;
    max-width: $max-width;
    width: 100%;
    box-sizing: border-box;
    font-family: $main-font;
    font-size: 12px;
}
.warning-wrap {
	background-color: #db8300;
}
.error-wrap {
	background-color: $red;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
}

.button {
    border: 0;
    text-transform: uppercase;
    font-size: 9px;
    background: #816ed7;
    color: #fff;
    border-radius: 6px;
    height: 30px;
    padding: 8px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-family: $main-font;
    cursor: pointer;
    .icon {
        width: 12px;
        margin-right: 6px;
    }
}

:deep(.vs__dropdown-toggle) {
    border: 0;
    background: $input-bg;
    border-radius: 16px;
    padding: 0px 2px 4px 2px
}
:deep(.vs__selected) {
    font-family: $main-font;
    font-size: 12px;
    color: $black;
    font-weight: 600;
}
:deep(.vs__dropdown-menu) {
    font-family: $main-font;
    font-size: 14px;
    color: $black;
    z-index: 30;
    border-radius: 8px;
    border: 0;
    min-width: 270px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis;
    padding-top: 0;
    padding-bottom: 0;
    left: auto;
    right: 0;
}
:deep(.vs__dropdown-option) {
    white-space: pre-wrap;
}
:deep(.vs--single.vs--open .vs__selected) {
    position: relative;
}

:deep(.vs__dropdown-option--disabled) {
    position: sticky;
    top: 0px;
    left: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #816ed7;
    color: $white;
    &:first-child:before{
        content: "";
        width: 100%;
        background: $white;
        height: 1px;
        position: absolute;
        top: -1px;
        left: 0;
    }
}

:deep(.vs__dropdown-option--highlight) {
    background: #f3efff;
    color: #231b52;
}
</style>