<template>
  <convertor></convertor>
</template>

<script>
import Convertor from "@/components/Convertor";

export default {
  name: 'App',
  components: {
    Convertor,
  }
}
</script>