<template>
    <div class="">

    </div>
</template>

<script>
export default {
    name: "TokenList"
}
</script>

<style scoped>

</style>